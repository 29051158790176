

export default {
    name:'v2',
    created() {

        let t = this;

        t.statusDict = listToDict(t.list.status);

        document.body.classList.add('pc');

        t.month = (new Date()).getMonth() + 1;

        t.startDate = new Date((new Date()).format('yyyy/MM/dd'));
        t.endDate = new Date(t.startDate.getFullYear(), t.startDate.getMonth(), t.startDate.getDate() + 28);

        axios.get('employee').then(res => {
            if (res.data.status == 1) {
                t.list.cleaner = res.data.options.items;
                t.cleanerDict = {};

                t.list.cleaner.forEach(cleaner => {
                    t.cleanerDict[cleaner.id] = cleaner;
                });

                t.loadData();
            }
        });

        t.days = 28;

        let todayText = t.today.format('MM/dd');

        for (let i = 1; i <= t.days; i++) {
            let d = new Date(t.startDate.format('yyyy/MM/dd'));

            d.setDate(d.getDate() + i - 1);

            let date = d.format('MM/dd');

            t.monthGrid[date] = {
                k: 0,
                s: 0,
                suffix: ['日', '月', '火', '水', '木', '金', '土'][d.getDay()],
                is_today: date == todayText
            };
        }

    },
    activated() {

        let t = this;

    },
    methods: {

        loadData() {

            let t = this;

            t.inited = false;

            t.roomGrid = {};
            t.checkInDict = {};
            t.checkOutDict = {};
            t.extraTaskDict = {};
            t.roomCheckInDict = {};
            t.ksDict = {};
            t.roomDict = {};

            axios.get('room').then(res => {

                if (res.data.status == 1) {

                    let arr = res.data.options.items;

                    arr.forEach(room => {
                        room.selected = false;
                        room.invisible = true;
                        t.roomDict[room.id] = room;
                    });

                    t.list.room = arr;

                    axios.get('group').then(res => {
                        if (res.data.status == 1) {
                            let arr = res.data.options.items;
                            arr.forEach(group => {
                                group.rooms = [];
                                let roomIds = group.room_ids.split(',');
                                roomIds.forEach(roomId => {
                                    group.rooms.push(t.roomDict[roomId]);
                                });
                            });

                            t.list.group = arr;

                            axios.get('order?_with=tasks&date=' + t.currentDate.format('yyyy/MM/dd')).then(res => {
                                if (res.data.status == 1) {

                                    let arr = res.data.options.items;

                                    arr.forEach(order => {
                                        let checkInDate = order.checkin_date.replace(/\d{4}[\/-]/, '').replace('-', '/');
                                        if (!t.checkInDict[checkInDate])
                                            t.checkInDict[checkInDate] = {};
                                        t.checkInDict[checkInDate][order.room_id] = order;

                                        if (!t.roomCheckInDict[order.room_id])
                                            t.roomCheckInDict[order.room_id] = {};
                                        t.roomCheckInDict[order.room_id][checkInDate] = order;

                                        if (!t.ksDict[checkInDate])
                                            t.ksDict[checkInDate] = { k: 0, s: 0 };

                                        if (order.k) {
                                            t.ksDict[checkInDate].k += Number(order.k);
                                            t.ksTotal.k += Number(order.k);
                                        }

                                        if (order.s) {
                                            t.ksDict[checkInDate].s += Number(order.s);
                                            t.ksTotal.s += Number(order.s);
                                        }

                                        let checkOutDate = order.checkout_date.replace(/\d{4}[\/-]/, '').replace('-', '/');

                                        if (!t.checkOutDict[checkOutDate])
                                            t.checkOutDict[checkOutDate] = {};
                                        t.checkOutDict[checkOutDate][order.room_id] = order;

                                        if (order.tasks) {
                                            order.tasks.forEach(task => {
                                                if (task.is_extra == 1) {
                                                    let cleanDate = task.date.replace(/\d{4}[\/-]/, '').replace('-', '/');
                                                    if (!t.extraTaskDict[cleanDate])
                                                        t.extraTaskDict[cleanDate] = {};
                                                    t.extraTaskDict[cleanDate][order.room_id] = task;
                                                }
                                            });
                                        }

                                    });

                                    let dateExtras = res.data.options.date_extras;

                                    if (dateExtras)
                                        dateExtras.forEach(item => {
                                            if (!t.dateExtraDict[item.room_id])
                                                t.dateExtraDict[item.room_id] = {};
                                            t.dateExtraDict[item.room_id][item.date] = item;
                                        });

                                    t.initRoomGrid();
                                }
                            });

                        }
                    });
                }
            });

        },

        onSelectCurrentDate(d) {
            let t = this;

            t.currentDate = d;

            t.refreshCurrentStats();

            $('.beautyscroll').scrollLeft(0);

            t.showDatePopover = false;

        },

        onSelectStartDate(d) {
            let t = this;

            t.currentDate = d;

            t.startDate = d;

            t.endDate = new Date(t.startDate.getFullYear(), t.startDate.getMonth(), t.startDate.getDate() + 28);

            t.days = 28;

            let todayText = t.today.format('MM/dd');

            t.monthGrid = {};

            for (let i = 1; i <= t.days; i++) {
                let d = new Date(t.startDate.format('yyyy/MM/dd'));

                d.setDate(d.getDate() + i - 1);

                let date = d.format('MM/dd');

                t.monthGrid[date] = {
                    k: 0,
                    s: 0,
                    suffix: ['日', '月', '火', '水', '木', '金', '土'][d.getDay()],
                    is_today: date == todayText
                };
            }

            t.loadData();

            // t.refreshCurrentStats();

            t.showDatePopover2 = false;

        },

        refreshDateItem(gridItem) {

            let t = this;

            gridItem.bg_color = 'bg-pink';

            if (gridItem.clean_status == 99) {
                //无清扫员
                gridItem.bg_color = 'bg-pink';
            }
            else if (gridItem.clean_status == 1) {
                //有清扫员
                let cleanDeadline = (new Date(gridItem.checkout_date + ' 13:00:00')).valueOf();
                let now = (new Date()).valueOf();
                if (now > cleanDeadline) {
                    gridItem.clean_delayed = true;
                    gridItem.bg_color = 'bg-red';
                }
                else
                    gridItem.bg_color = 'bg-pink';
            }
            else if (gridItem.clean_status == 2) {
                //正在清扫
                gridItem.bg_color = 'bg-yellow';
            }
            else if (gridItem.clean_status == 3) {
                //清扫完成
                gridItem.bg_color = 'bg-green';
            }

            let d = new Date(t.today.getFullYear() + '/' + gridItem.date);
            let d2 = new Date(t.today.format('yyyy/MM/dd'));

            d2.setDate(d2.getDate() + 3);

            if (gridItem.clean_status == 99 && d2 > d && d >= new Date(t.today.format('yyyy/MM/dd'))) {
                gridItem.bg_color = 'bg-orange';
            }

            if (gridItem.checkin_date) {
                d = new Date(gridItem.checkin_date);
                d2 = new Date(t.today.getFullYear() + '/' + gridItem.date);

                d = d.format('yyyy/MM/dd');
                d2 = d2.format('yyyy/MM/dd');

                if (d == d2 && gridItem.override_clean_status == 3) {
                    gridItem.bg_color = 'bg-green';
                }
            }

        },

        refreshContinuous(gridItem, grid) {

            let t = this;

            let checkInItem = gridItem.checkInItem;

            if (checkInItem && checkInItem.continuous_days > 1) {

                let room = t.roomDict[gridItem.room_id];

                let date = (new Date(checkInItem.checkin_date)).format('MM/dd');

                for (let i = 1; i < checkInItem.continuous_days; i++) {

                    let d = new Date(date);

                    d.setDate(d.getDate() + i);

                    let date2 = d.format('MM/dd');

                    if (!grid[date2])
                        grid[date2] = {};

                    let dateItem = grid[date2][room.id];

                    if (!dateItem)
                        dateItem = grid[date2][room.id] = {
                            room_name: room.name,
                            room_id: room.id,
                            date: date2,
                            id: null,
                            cleaner_firstname: '',
                            cleaner_lastname: '',
                            cleaner_id: 0,
                            source: '',
                            status: 99,
                            bg_color: 'bg-grey',
                            // checkin_date: '',
                            // checkout_date: '',
                            // count_audlt: 0,
                            // count_child: 0,
                            // count_baby: 0,
                            // k: 0,
                            // s: 0,
                            continuous_days: 0,
                            is_continuous: false,
                            is_last_continuous: false,
                            cid: null,
                            form_status: 1,
                            emergency_status: 0,
                        };

                    dateItem.sourceItem = checkInItem;
                    dateItem.is_continuous = true;
                    dateItem.is_last_continuous = i == checkInItem.continuous_days - 1;
                    dateItem.bg_color = gridItem.bg_color + '2';
                    dateItem.sourceCheckInItem = gridItem.checkInItem;

                    if (t.extraTaskDict[date2] && t.extraTaskDict[date2][room.id]) {
                        let task = t.extraTaskDict[date2][room.id];
                        if (task.cleaner_id) {
                            dateItem.task_id = task.id;
                            dateItem.cleaner_id = task.cleaner_id;
                            dateItem.cleaner_firstname = t.cleanerDict[task.cleaner_id].firstname;
                            dateItem.clean_status = task.status;
                            dateItem.cleaner_lastname = t.cleanerDict[task.cleaner_id].lastname;
                        }
                        t.refreshDateItem(dateItem);
                    }
                }

            }
        },

        refreshCurrentStats() {

            let t = this;

            t.currentStats = {
                task: 0,
                continuous: 0,
                empty: 0,
                checkin: 0
            };

            let currentDateStr = t.currentDate.format('MM/dd');

            t.list.group.forEach(group => {

                group.rooms.forEach(room => {

                    for (const date in t.monthGrid) {

                        let gridItem = t.roomGrid[date][room.id];

                        if (gridItem) {
                            gridItem.isCurrentDate = false;
                            gridItem.isA = !!((gridItem.checkOutItem && !!gridItem.checkOutItem.cleaner_id) ||
                                (gridItem.checkInItem && gridItem.checkInItem.emergency_status == 2) ||
                                (gridItem.checkInItem && gridItem.checkInItem.emergency_status == 3) ||
                                gridItem.checkOutItem);
                            gridItem.isB = gridItem.is_continuous;
                            gridItem.isC = !gridItem.checkInItem && !gridItem.is_continuous;
                            gridItem.isD = !!(gridItem.checkInItem);

                            if (currentDateStr == date) {

                                gridItem.isCurrentDate = true;

                                if (gridItem.isA)
                                    t.currentStats.task++;

                                if (gridItem.isB)
                                    t.currentStats.continuous++;

                                if (gridItem.isC)
                                    t.currentStats.empty++;

                                if (gridItem.isD)
                                    t.currentStats.checkin++;
                            }
                        }
                    }

                });
            });
        },

        initRoomGrid() {

            let t = this;
            let grid = {};

            function fillGrid() {

                t.list.group.forEach(group => {

                    group.rooms.forEach(room => {

                        t.days = 28;

                        for (let j = -15; j <= t.days; j++) {
                            let d = new Date(t.startDate.format('yyyy/MM/dd'));

                            d.setDate(d.getDate() + j - 1);

                            let date = d.format('MM/dd');

                            if (!grid[date])
                                grid[date] = {};

                            let gridItem = grid[date][room.id];

                            if (!gridItem)
                                gridItem = grid[date][room.id] = {
                                    room_name: room.name,
                                    room_id: room.id,
                                    date: date,
                                    id: null,
                                    cleaner_firstname: '',
                                    cleaner_lastname: '',
                                    cleaner_id: 0,
                                    // source: '',
                                    status: 99,
                                    bg_color: 'bg-grey',
                                    // checkin_date: '',
                                    // checkout_date: '',
                                    // count_audlt: 0,
                                    // count_child: 0,
                                    // count_baby: 0,
                                    // k: 0,
                                    // s: 0,
                                    continuous_days: 0,
                                    is_continuous: false,
                                    is_last_continuous: false,
                                    cid: null,
                                    form_status: 1,
                                    emergency_status: 0,
                                };


                            if (t.checkInDict[date] && t.checkInDict[date][room.id]) {

                                let checkInItem = {};

                                checkInItem.date = date;

                                // checkInItem.bg_color = 'bg-pink';

                                mirror(t.checkInDict[date][room.id], checkInItem);

                                checkInItem.bell_color = 'fill-white';

                                if (checkInItem.form_status == 1)
                                    checkInItem.bell_color = 'fill-red';

                                if (new Date(t.today.getFullYear() + '/' + date).valueOf() < t.today.valueOf())
                                    checkInItem.bell_color = 'fill-outline';

                                t.refreshDateItem(checkInItem);

                                gridItem.checkInItem = checkInItem;
                            }

                            if (t.checkOutDict[date] && t.checkOutDict[date][room.id]) {

                                let checkOutItem = {};

                                checkOutItem.date = date;

                                mirror(t.checkOutDict[date][room.id], checkOutItem);

                                t.refreshDateItem(checkOutItem);


                                gridItem.checkOutItem = checkOutItem;
                            }

                            if (gridItem.checkInItem) {

                                gridItem.checkInItem.room_name = gridItem.room_name;

                                gridItem.checkInItem.room_id = gridItem.room_id;

                                gridItem.checkInItem.dateInGrid = date;

                            }

                            if (gridItem.checkInItem) {

                                let checkInItem = gridItem.checkInItem;

                                gridItem.bg_color = checkInItem.bg_color;

                                if (checkInItem.continuous_days > 1) {

                                    for (let i = 1; i < checkInItem.continuous_days; i++) {

                                        let d = new Date(date);

                                        d.setDate(d.getDate() + i);

                                        let date2 = d.format('MM/dd');

                                        if (!grid[date2])
                                            grid[date2] = {};

                                        let dateItem = grid[date2][room.id];

                                        if (!dateItem)
                                            dateItem = grid[date2][room.id] = {
                                                room_name: room.name,
                                                room_id: room.id,
                                                date: date2,
                                                id: null,
                                                cleaner_firstname: '',
                                                cleaner_lastname: '',
                                                cleaner_id: 0,
                                                source: '',
                                                status: 99,
                                                bg_color: 'bg-grey',
                                                // checkin_date: '',
                                                // checkout_date: '',
                                                // count_audlt: 0,
                                                // count_child: 0,
                                                // count_baby: 0,
                                                // k: 0,
                                                // s: 0,
                                                continuous_days: 0,
                                                is_continuous: false,
                                                is_last_continuous: false,
                                                cid: null,
                                                form_status: 1,
                                                emergency_status: 0,
                                            };

                                        dateItem.sourceItem = checkInItem;
                                        dateItem.is_continuous = true;
                                        dateItem.is_last_continuous = i == checkInItem.continuous_days - 1;
                                        dateItem.bg_color = gridItem.bg_color + '2';
                                        dateItem.continuous_bg_color = gridItem.bg_color + '2';

                                        if (t.extraTaskDict[date2] && t.extraTaskDict[date2][room.id]) {
                                            let task = t.extraTaskDict[date2][room.id];
                                            if (task.cleaner_id) {
                                                dateItem.cleaner_id = task.cleaner_id;
                                                dateItem.cleaner_firstname = t.cleanerDict[task.cleaner_id].firstname;
                                                dateItem.cleaner_lastname = t.cleanerDict[task.cleaner_id].lastname;
                                            }
                                            t.refreshDateItem(dateItem);
                                        }
                                    }

                                }

                                t.refreshContinuous(gridItem, grid);

                            }

                            if (gridItem.checkOutItem) {
                                let checkOutItem = gridItem.checkOutItem;
                                gridItem.bg_color = checkOutItem.bg_color;
                                t.refreshContinuous(gridItem, grid);
                            }
                        }


                    });

                });

                t.list.group.forEach(group => {

                    group.rooms.forEach(room => {

                        t.days = 28;

                        for (let j = -15; j <= t.days; j++) {
                            let d = new Date(t.startDate.format('yyyy/MM/dd'));

                            d.setDate(d.getDate() + j - 1);

                            let date = d.format('MM/dd');

                            if (!grid[date])
                                grid[date] = {};

                            let gridItem = grid[date][room.id];

                            if (gridItem.checkOutItem && !gridItem.checkInItem) {

                                let minDateVal = 0;
                                let minDate = '';
                                let dateVal = (new Date(t.today.getFullYear() + '/' + date)).valueOf();

                                for (const date2 in t.roomCheckInDict[room.id]) {

                                    let date2Val = (new Date(t.today.getFullYear() + '/' + date2)).valueOf();

                                    if (date2Val > dateVal) {
                                        if (minDateVal == 0) {
                                            minDateVal = date2Val;
                                            minDate = date2;
                                        }
                                        else if (date2Val < minDateVal) {
                                            minDateVal = date2Val;
                                            minDate = date2;
                                        }
                                    }
                                }

                                if (minDate) {
                                    if (grid[minDate] && grid[minDate][room.id] && grid[minDate][room.id].checkInItem) {
                                        gridItem.nextCheckInItem = grid[minDate][room.id].checkInItem;
                                        gridItem.nextCheckInGridItem = grid[minDate][room.id];

                                        if (gridItem.nextCheckInItem) {
                                            gridItem.nextCheckInItem.cleaned_at = date;
                                            gridItem.nextCheckInItem.bg_color = gridItem.bg_color;
                                            gridItem.nextCheckInGridItem.bg_color = gridItem.bg_color;

                                            let checkInItem = gridItem.nextCheckInItem;
                                            if (checkInItem.continuous_days > 1) {

                                                for (let i = 1; i < checkInItem.continuous_days; i++) {

                                                    let d = new Date(checkInItem.date);

                                                    d.setDate(d.getDate() + i);

                                                    let date2 = d.format('MM/dd');

                                                    if (!grid[date2])
                                                        grid[date2] = {};

                                                    let dateItem = grid[date2][room.id];

                                                    // if (!dateItem)
                                                    //     dateItem = grid[date2][room.id] = {
                                                    //         room_name: room.name,
                                                    //         room_id: room.id,
                                                    //         date: date2,
                                                    //         id: null,
                                                    //         cleaner_firstname: '',
                                                    //         cleaner_lastname: '',
                                                    //         cleaner_id: 0,
                                                    //         source: '',
                                                    //         status: 99,
                                                    //         bg_color: 'bg-grey',
                                                    //         // checkin_date: '',
                                                    //         // checkout_date: '',
                                                    //         // count_audlt: 0,
                                                    //         // count_child: 0,
                                                    //         // count_baby: 0,
                                                    //         // k: 0,
                                                    //         // s: 0,
                                                    //         continuous_days: 0,
                                                    //         is_continuous: false,
                                                    //         is_last_continuous: false,
                                                    //         cid: null,
                                                    //         form_status: 1,
                                                    //         emergency_status: 0,
                                                    //     };

                                                    dateItem.sourceItem = checkInItem;
                                                    dateItem.is_continuous = true;
                                                    dateItem.is_last_continuous = i == checkInItem.continuous_days - 1;
                                                    dateItem.bg_color = gridItem.bg_color + '2';
                                                    dateItem.continuous_bg_color = gridItem.bg_color + '2';

                                                    if (t.extraTaskDict[date2] && t.extraTaskDict[date2][room.id]) {
                                                        let task = t.extraTaskDict[date2][room.id];
                                                        if (task.cleaner_id) {
                                                            dateItem.cleaner_id = task.cleaner_id;
                                                            dateItem.cleaner_firstname = t.cleanerDict[task.cleaner_id].firstname;
                                                            dateItem.cleaner_lastname = t.cleanerDict[task.cleaner_id].lastname;
                                                        }
                                                        t.refreshDateItem(dateItem);
                                                    }
                                                }

                                            }

                                            t.refreshContinuous(gridItem.nextCheckInGridItem, t.roomGrid);
                                        }

                                        let fullDate = (new Date(t.today.getFullYear() + '/' + gridItem.date)).format('yyyy-MM-dd');

                                        if (t.dateExtraDict[room.id] && t.dateExtraDict[room.id][fullDate]) {
                                            let dateExtra = t.dateExtraDict[room.id][fullDate];
                                            gridItem.dateExtra = dateExtra;
                                        }
                                    }
                                }
                            }
                        }
                    });
                });
            }

            fillGrid();

            t.roomGrid = grid;

            t.refreshInvisible();

            t.refreshCurrentStats();


            t.inited = true;

            // setTimeout(function () {
            //     $('.beautyscroll').scrollLeft((t.today.getDate() - 3) * 120);
            // }, 100);
        },

        refreshInvisible() {
            let t = this;
            let date = t.currentDate.format('MM/dd');
            let roomGrid = t.roomGrid;

            t.list.group.forEach(group => {

                group.rooms.forEach(room => {

                    if (t.toggle.all) {
                        room.invisible = false;
                    }
                    else {
                        room.invisible = true;

                        if ((roomGrid[date][room.id].isA && t.toggle.task) ||
                            (roomGrid[date][room.id].isB && t.toggle.continuous) ||
                            (roomGrid[date][room.id].isC && t.toggle.empty) ||
                            (roomGrid[date][room.id].isD && t.toggle.checkin))
                            room.invisible = false;
                    }
                });

            });
        },

        selectRoom(room) {
            room.selected = !room.selected;
        },

        deleteGroup(group) {

            let t = this;

            A.safety('Are you sure to delete this group?').then(res => {
                if (res.isConfirmed) {
                    t.list.group.remove(group);
                    t.$forceUpdate();
                    axios.delete('group/' + group.id).then(res => {
                        if (res.data.status == 1) {

                        }
                    });

                }
            });
        },

        newGroup() {

            let t = this;

            t.list.room.forEach(room => {
                room.selected = false;
            });

            t.editingGroup = null;

            t.groupForm = {
                name: '',
                room_ids: null
            };

            let f = this.groupForm;

            t.showGroupEditor = true;
        },

        editGroup(group) {

            let t = this;
            let roomIds = group.room_ids.split(',');
            t.editingGroup = group;

            t.list.room.forEach(room => {
                room.selected = roomIds.indexOf(room.id.toString()) > -1;
            });

            mirror(group, t.groupForm);

            t.showGroupEditor = true;
        },

        saveGroup() {
            let t = this;

            let f = t.groupForm;

            if (!f.name) {
                A.err('Please enter group name')
                return;
            }

            let roomIds = [];

            t.list.room.forEach(room => {
                if (room.selected)
                    roomIds.push(room.id);
            });

            if (roomIds.length == 0) {
                A.err('Please select at least one house');
                return;
            }

            f.room_ids = roomIds.join(',');

            if (t.busying)
                return;

            t.busying = true;

            let data = getFields(f, ['name', 'room_ids']);

            if (f.id) {
                axios.put('group/' + f.id, data).then(res => {

                    t.busying = false;

                    if (res.data.status == 1) {
                        t.showGroupEditor = false;
                        t.loadData();
                    }
                });
            }
            else {
                axios.post('group', data).then(res => {

                    t.busying = false;

                    if (res.data.status == 1) {
                        t.showGroupEditor = false;
                        t.loadData();
                    }
                });
            }


        },

        editOrder(date, room_id) {

            let t = this;

            let gridItem = t.roomGrid[date][room_id];

            t.orderForm = {};
            if (gridItem.checkInItem) {
                t.editingItem = gridItem.checkInItem;
                t.editingItem.isEdit = true;
                mirror(gridItem.checkInItem, t.orderForm);
                t.showOrderEditor = true;
            }


            else {
                mirror(t.orderForm_o, t.orderForm);
                let room = t.roomDict[room_id];
                t.orderForm.max_k = room.max_k;
                t.orderForm.max_s = room.max_s;
                t.orderForm.room_id = room_id;
                t.orderForm.checkin_date = t.today.getFullYear() + '/' + gridItem.date;
                t.editingItem = gridItem;
                t.editingItem.isEdit = false;
                t.orderForm.source = 'MM';
                t.showOrderEditor = true;
                t.calcKS();
            }

        },

        deleteOrder() {

            let t = this;

            A.safety('Are you sure to delete this appointment?').then(res => {
                if (res.isConfirmed) {
                    t.showOrderEditor = false;
                    t.$forceUpdate();
                    axios.delete('order/' + t.editingItem.id).then(res => {
                        if (res.data.status == 1) {
                            t.loadData();
                        }
                    });

                }
            });

        },

        saveOrder() {
            let t = this;

            let f = t.orderForm;

            if (!f.checkin_date) {
                A.err('Please enter check-in date')
                return;
            }

            if (!f.checkout_date) {
                A.err('Please enter check-out date')
                return;
            }

            let leftDays = t.calcDateDiffDays(f.checkin_date, f.checkout_date);

            if (leftDays < 1) {
                A.err('Please check the check-out date')
                return;
            }

            f.continuous_days = leftDays;

            if (t.busying)
                return;

            t.busying = true;

            f.room_id = t.editingItem.room_id;

            let fields = ['room_id', 'checkin_date', 'checkout_date', 'count_audlt', 'count_child', 'count_baby', 'remark', 'source', 'continuous_days'];

            if (!f.id || f._overwrite) {
                fields = fields.append(['k', 's']);
            }

            let data = getFields(f, fields);

            if (f.id) {
                axios.put('order/' + f.id, data).then(res => {

                    t.busying = false;

                    if (res.data.status == 1) {
                        t.showOrderEditor = false;
                        t.loadData();
                    }
                });
            }
            else {
                axios.post('order', data).then(res => {

                    t.busying = false;

                    if (res.data.status == 1) {
                        t.showOrderEditor = false;
                        t.loadData();
                    }
                });
            }

        },

        editCleaner(date, room_id) {

            let t = this;

            let gridItem = t.roomGrid[date][room_id];

            if(!gridItem.checkOutItem)
                return;

            // if (t.roomGrid[date][room_id].sourceCheckInItem)
                // gridItem = t.roomGrid[date][room_id].sourceCheckInItem;

            // else
            //  if (t.roomGrid[date][room_id].checkOutItem)
                // gridItem = t.roomGrid[date][room_id].checkOutItem;

            // else if (t.roomGrid[date][room_id].checkInItem)
            //     gridItem = t.roomGrid[date][room_id].checkInItem;

            // else if (t.roomGrid[date][room_id].nextCheckInItem)
            //     gridItem = t.roomGrid[date][room_id].nextCheckInItem;

            t.editingItem = gridItem;

            t.editingItem.cid = gridItem.checkOutItem.cleaner_id;

            t.selectedCleaner = t.cleanerDict[gridItem.checkOutItem.cleaner_id];

            t.selectedStatus = t.statusDict[gridItem.checkOutItem.clean_status];

            t.showCleanerEditor = true;
        },

        saveCleaner() {
            let t = this;

            let gridItem = t.editingItem;

            let cid;

            if (t.selectedCleaner == -1)
                cid = null;
            else if (t.selectedCleaner)
                cid = t.selectedCleaner.id;

            if (t.busying)
                return;

            t.busying = true;

            let f = { cleaner_id: cid };

            if (gridItem.is_continuous) {
                f.date = (new Date(t.today.getFullYear() + '/' + gridItem.date)).format('yyyy/MM/dd');
                f.room_id = gridItem.room_id;
                f.order_id = gridItem.sourceCheckInItem.id;
                f.status = 1;
                f.clean_status = 1;
            }
            else {
                f.id = t.editingItem.checkOutItem.id;
                f.clean_status = 1;
                if (t.selectedStatus)
                    f.clean_status = t.selectedStatus.id;
            }

            axios.post('order/setCleaner', f).then(res => {

                t.busying = false;

                if (res.data.status == 1) {
                    if (cid) {
                        if (gridItem.is_continuous) {

                            gridItem.checkOutItem.cleaner_id = cid;
                            gridItem.checkOutItem.clean_status = f.clean_status;
                            gridItem.checkOutItem.cleaner_firstname = t.cleanerDict[gridItem.checkOutItem.cleaner_id].firstname;
                            gridItem.checkOutItem.cleaner_lastname = t.cleanerDict[gridItem.checkOutItem.cleaner_id].lastname;

                            t.refreshDateItem(gridItem, true);
                        }
                        else {
                            gridItem.checkOutItem.cleaner_id = cid;
                            gridItem.checkOutItem.clean_status = f.clean_status;
                            gridItem.checkOutItem.cleaner_firstname = t.cleanerDict[gridItem.checkOutItem.cleaner_id].firstname;
                            gridItem.checkOutItem.cleaner_lastname = t.cleanerDict[gridItem.checkOutItem.cleaner_id].lastname;

                            t.refreshDateItem(gridItem.checkOutItem, true);
                            gridItem.bg_color = gridItem.checkOutItem.bg_color;

                            if (gridItem.nextCheckInItem) {
                                gridItem.nextCheckInItem.bg_color = gridItem.bg_color;
                                gridItem.nextCheckInGridItem.bg_color = gridItem.bg_color;
                                t.refreshContinuous(gridItem.nextCheckInGridItem, t.roomGrid);
                            }
                        }


                    }
                    else {
                        if (gridItem.is_continuous) {

                            gridItem.checkOutItem.cleaner_id = null;
                            gridItem.checkOutItem.clean_status = 99;
                            gridItem.checkOutItem.cleaner_firstname = null;
                            gridItem.checkOutItem.cleaner_lastname = null;

                            gridItem.checkOutItem.bg_color = gridItem.checkOutItem.continuous_bg_color;
                        }
                        else {
                            gridItem.checkOutItem.cleaner_id = null;
                            gridItem.checkOutItem.clean_status = 99;
                            gridItem.checkOutItem.cleaner_firstname = null;
                            gridItem.checkOutItem.cleaner_lastname = null;

                            t.refreshDateItem(gridItem.checkOutItem, true);
                            gridItem.bg_color = gridItem.checkOutItem.bg_color;
                        }
                    }

                    t.refreshContinuous(gridItem, t.roomGrid);

                    t.showCleanerEditor = false;
                }
            });
        },

        editEmergency2(gridItem) {

            let t = this;

            if (gridItem.emergency_status != 2)
                return;

            t.editingItem = gridItem;

            t.showEmergency2Editor = true;
        },

        resolveEmergency2() {
            let t = this;

            let gridItem = t.editingItem;

            if (t.busying)
                return;

            t.busying = true;

            axios.put('order/' + gridItem.id, { emergency_status: 1 }).then(res => {

                t.busying = false;

                if (res.data.status == 1) {
                    gridItem.emergency_status = 1;
                    t.showEmergency2Editor = false;
                }
            });
        },

        editEmergency3(gridItem) {

            let t = this;

            if (!gridItem.nextCheckInItem && !gridItem.checkInItem)
                return;

            let go = (gridItem.checkInItem && gridItem.checkInItem.emergency_status == 3) ||
                (gridItem.nextCheckInItem && gridItem.nextCheckInItem.emergency_status == 3) ||
                (gridItem.nextCheckInItem && gridItem.dateExtra);

            if (!go)
                return;

            t.editingItem = gridItem;

            t.showEmergency3Editor = true;
        },

        resolveEmergency3() {
            let t = this;

            let gridItem = t.editingItem;

            if (t.busying)
                return;

            t.busying = true;

            if (gridItem.nextCheckInItem) {

                if (gridItem.dateExtra) {
                    axios.put('dateExtra/' + gridItem.dateExtra.id, { 'status': 2 }).then(res => {
                        t.busying = false;
                        gridItem.dateExtra = null;
                        t.showEmergency3Editor = false;
                    });
                } else {
                    axios.put('order/' + gridItem.nextCheckInItem.id, { emergency_status: 1 }).then(res => {

                        t.busying = false;

                        if (res.data.status == 1) {
                            gridItem.nextCheckInItem.emergency_status = 1;
                            t.showEmergency3Editor = false;
                        }
                    });
                }

            }
            else if (gridItem.checkInItem.id) {
                axios.put('order/' + gridItem.checkInItem.id, { emergency_status: 1 }).then(res => {

                    t.busying = false;

                    if (res.data.status == 1) {
                        gridItem.checkInItem.emergency_status = 1;
                        t.showEmergency3Editor = false;
                    }
                });
            }
        },

        goContact() {
            this.showUnfillForm = false;
        },

        editUnfill(gridItem) {

            let t = this;

            if (gridItem.form_status == 2) {

                let width = 790;
                let height = 820;
                let leftPosition = (window.screen.width - width) / 2;
                let topPosition = (window.screen.height - height) / 2;
                let windowFeatures = 'width=' + width + ',height=' + height + ',left=' + leftPosition + ',top=' + topPosition + ',resizable=yes,scrollbars=yes';

                localStorage['ignorePasswordOnce'] = 1;
                let popup = window.open(location.origin + '/#/a4form?date=' + gridItem.checkin_date + '&rid=' + gridItem.room_id, '_blank', windowFeatures);

                return;
            }

            if (gridItem.form_status != 1)
                return;

            t.editingItem = gridItem;

            t.showUnfillForm = true;
        },


        resolveUnfill() {
            let t = this;

            let gridItem = t.editingItem;

            let width = 420;
            let height = 800;
            let leftPosition = (window.screen.width - width) / 2;
            let topPosition = (window.screen.height - height) / 2;
            let windowFeatures = 'width=' + width + ',height=' + height + ',left=' + leftPosition + ',top=' + topPosition + ',resizable=yes,scrollbars=yes';

            let popup = window.open(location.origin + '/#/form?rid=' + gridItem.room_id, '_blank', windowFeatures);

            function handleMsg(event) {
                if (event.source === popup && typeof event.data === 'string') {
                    window.removeEventListener('message', handleMsg);
                    gridItem.form_status = 2;
                    gridItem.bell_color = 'fill-white';
                    t.$forceUpdate();
                }
            }

            window.addEventListener('message', handleMsg);

            // if (t.busying)
            //     return;

            // t.busying = true;


            // axios.put('order/' + gridItem.id, { form_status: 2 }).then(res => {

            //     t.busying = false;

            //     if (res.data.status == 1) {
            //         gridItem.form_status = 2;
            //         gridItem.bell_color = 'fill-white';
            //         t.showUnfillForm = false;
            //     }

            // });
        },

        editCleanStatus(gridItem) {

            let t = this;

            if (gridItem.checkInItem && (gridItem.checkInItem.override_clean_status || gridItem.checkInItem.clean_status == 3))
                return;

            t.editingItem = gridItem.checkInItem;

            t.editingItem._o = gridItem;

            t.showCleanStatusEditor = true;
        },

        resolveCleanStatus() {
            let t = this;

            let gridItem = t.editingItem._o;

            if (t.busying)
                return;

            t.busying = true;

            axios.put('order/' + t.editingItem.id, { override_clean_status: 3 }).then(res => {

                t.busying = false;

                if (res.data.status == 1) {
                    t.editingItem.override_clean_status = 3;
                    t.refreshDateItem(t.editingItem, true);
                    t.showCleanStatusEditor = false;

                    gridItem.bg_color = t.editingItem.bg_color;
                }

            });
        },

        calcKS() {
            let t = this;
            let f = t.orderForm;

            let room = t.roomDict[f.room_id];
            let type = room.type;

            let k, s;

            let countTotal = f.count_audlt + f.count_child;

            if (type == 'A') {
                k = 1;
                s = 2;
                if (countTotal > 4)
                    s = countTotal - 2;
            } else if (type == 'B') {
                k = 2;
                s = 2;
                if (countTotal > 4)
                    s = countTotal - 2;
            } else if (type == 'C') {
                k = 1;
                s = 0;
            } else if (type == 'D') {
                k = 0;
                s = countTotal;
            } else {
                k = Math.ceil(f.count_audlt / 2.0);
            }

            t.orderForm.k = k;
            t.orderForm.s = s;

            t.$forceUpdate();
        },

        refreshToggles(key) {

            let t = this;

            switch (key) {
                case 'task':
                    if (t.toggle.task)
                        t.toggle.continuous = t.toggle.checkin = t.toggle.empty = t.toggle.all = false;
                    else
                        t.toggle.continuous = t.toggle.checkin = t.toggle.empty = true;
                    break;

                case 'continuous':
                    if (t.toggle.continuous)
                        t.toggle.task = t.toggle.checkin = t.toggle.empty = t.toggle.all = false;
                    else
                        t.toggle.task = t.toggle.checkin = t.toggle.empty = true;
                    break;
                case 'checkin':

                    if (t.toggle.checkin)
                        t.toggle.task = t.toggle.continuous = t.toggle.empty = t.toggle.all = false;
                    else
                        t.toggle.task = t.toggle.continuous = t.toggle.empty = true;
                    break;
                case 'empty':

                    if (t.toggle.empty)
                        t.toggle.task = t.toggle.continuous = t.toggle.checkin = t.toggle.all = false;
                    else
                        t.toggle.task = t.toggle.continuous = t.toggle.checkin = true;
                    break;
                case 'all':

                    if (t.toggle.all) {
                        t.toggle.task = t.toggle.continuous = t.toggle.checkin = t.toggle.empty = false;
                    }
                    else {
                        t.toggle.continuous = t.toggle.checkin = t.toggle.empty = false;
                        t.toggle.task = true;
                    }
                    break;

            }

            if (key != 'all') {
                if (!t.toggle.task && !t.toggle.continuous && !t.toggle.checkin && !t.toggle.empty)
                    t.toggle.all = true;
            }

            t.refreshInvisible();
        },

        viewCleanReport(gridItem) {

            let t = this;
            let width = 790;
            let height = 820;
            let leftPosition = (window.screen.width - width) / 2;
            let topPosition = (window.screen.height - height) / 2;
            let windowFeatures = 'width=' + width + ',height=' + height + ',left=' + leftPosition + ',top=' + topPosition + ',resizable=yes,scrollbars=yes';

            let taskId;

            if (gridItem.checkOutItem)
                taskId = gridItem.checkOutItem.task_id;
            else if (gridItem.is_continuous)
                taskId = gridItem.task_id;

            let popup = window.open(location.origin + '/#/report?task_id=' + taskId, '_blank', windowFeatures);

            function handleMsg(event) {
                if (event.source === popup && typeof event.data === 'string') {
                    window.removeEventListener('message', handleMsg);

                    if (gridItem.checkOutItem) {
                        gridItem.checkOutItem.clean_status = 2;

                        t.refreshDateItem(gridItem.checkOutItem, true);

                        gridItem.bg_color = gridItem.checkOutItem.bg_color;

                        t.refreshContinuous(gridItem, t.roomGrid);
                    }
                    else {
                        gridItem.clean_status = 2;
                        gridItem.bg_color = 'bg-yellow';
                    }

                    if (gridItem.nextCheckInItem) {
                        gridItem.nextCheckInItem.bg_color = gridItem.bg_color;
                        gridItem.nextCheckInGridItem.bg_color = gridItem.bg_color;
                        t.refreshContinuous(gridItem.nextCheckInGridItem, t.roomGrid);
                    }

                    t.$forceUpdate();
                }
            }

            window.addEventListener('message', handleMsg);

            return;
        },

        printGridItem(gridItem) {
            console.log(gridItem);
        }

    },
    data() {
        return {
            minDate: new Date('2024/01/01'),
            currentDate: Date.today(),
            today: Date.today(),
            days: 28,
            editingGroup: null,
            editingItem: null,
            selectedCleaner: null,
            selectedStatus: null,
            groupForm: {},
            groupForm_o: {
                name: '',
                rooms_ids: null
            },
            orderForm: {},
            orderForm_o: {
                room_id: null,
                checkin_date: '',
                checkout_date: '',
                count_audlt: 1,
                count_child: 0,
                count_baby: 0,
                k: 1,
                s: 0,
                _overwrite: false,
                max_k: 99,
                max_s: 99
            },
            showDatePopover: false,
            showDatePopover2: false,
            showCleanerEditor: false,
            showCleanerPopover: false,
            showStatusPopover: false,
            showGroupEditor: false,
            showOrderEditor: false,
            showEmergency2Editor: false,
            showEmergency3Editor: false,
            showUnfillForm: false,
            showCleanStatusEditor: false,
            month: 2,
            list: {
                status: [
                    { id: 1, text: '未開始' },
                    { id: 2, text: '清掃中' },
                    { id: 3, text: '完　了' },
                ],
                room: [],
                group: [],
                cleaner: []
            },
            toggle: {
                all: true,
                task: false,
                continuous: false,
                empty: false,
                checkin: false
            },
            inited: false,
            monthGrid: {},
            roomGrid: {},
            checkInDict: {},
            checkOutDict: {},
            extraTaskDict: {},
            roomCheckInDict: {},
            statusDict: {},
            ksDict: {},
            ksTotal: { k: 0, s: 0 },
            roomDict: {},
            cleanerDict: {},
            dateExtraDict: {},
            currentStats: {
                task: 0,
                continuous: 0,
                empty: 0,
                checkin: 0
            }
        };
    },
    computed: {
        filteredRooms() {

            let t = this;
            let arr = [];

            let ids = [];

            t.list.group.forEach(group => {
                if (t.editingGroup && t.editingGroup.id == group.id)
                    return;
                if (group.rooms) {
                    group.rooms.forEach(room => {
                        ids.push(room.id);
                    })
                }
            });

            t.list.room.forEach(room => {
                if (ids.indexOf(room.id) < 0)
                    arr.push(room);
            });

            return arr;
        }
    }
};
