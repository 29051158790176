

export default {


    created() {

        let t = this;

        let tmpF = JSON.parse(localStorage['_tmpF'] ? localStorage['_tmpF'] : "{}");

        mirrorSet(t.originF, t.f);

        mirrorSet(tmpF, t.f);

        if (t.f.guest_num > 0)
            t.onGuestNumChange(t.f.guest_num);

        if (t.f.foreigner_num > 0)
            t.onForeignerNumChange(t.f.foreigner_num);

        if (t.f.rc_foreigner_num > 0)
            t.onRCForeignerNumChange(t.f.rc_foreigner_num);

        if (t.f.rc_urls) {
            let urls = t.f.rc_urls.split(',');

            t.passportUploader.items2 = [];
            urls.forEach(url => {
                t.passportUploader.items2.push(({ success: true, img_url: url, validation: false }));
            });
        }

        if (t.f.passport_urls) {
            let urls = t.f.passport_urls.split(',');
            let passportData = JSON.parse(t.f.passport_data);

            t.passportUploader.items = [];

            let i = 0;
            urls.forEach(url => {
                let data = passportData[i];
                t.passportUploader.items.push(({ success: true, data: data, img_url: url, validation: true }));
                i++;
            });
        }

        t.minDate = new Date();
        t.maxDate = new Date();
        t.minDate2 = new Date();
        t.maxDate2 = new Date();

        t.maxDate.setDate(t.maxDate.getDate() + 365);
        t.maxDate2.setDate(t.maxDate2.getDate() + 365);

        t.list.country = window['COUNTRY_LIST'];

        t.list.country.forEach(item => {
            item.icon = 'assets/img/flag/' + item.code + '.svg';
        });

        t.dict.country = listToDict(t.list.country, 'code');

        if (window._language == 'ja-jp')
            t.f.lang = 1;
        else if (window._language == 'en-us')
            t.f.lang = 3;
        else if (window._language == 'zh-cn')
            t.f.lang = 4;
        else if (window._language == 'ko-kr')
            t.f.lang = 5;

        t.initGenderList();

        t.loadData();

    },
    activated() {

        let t = this;

    },
    methods: {

        loadData() {
            let t = this;
            let p = getURLParams();
            if (p.rid) {
                axios.get('room/' + p.rid).then(res => {
                    if (res.data.status == 1) {
                        t.room = res.data.options.item;
                        t.f.room_id = t.room.id;
                        t.setRoomName();
                    }
                });
            }
            else if (p.r) {
                axios.get('room2/' + p.r).then(res => {
                    if (res.data.status == 1) {
                        t.room = res.data.options.item;
                        t.f.room_id = t.room.id;
                        t.setRoomName();
                    }
                });
            }
        },

        initGenderList() {

            let t = this;

            t.list.gender = [
                { id: 1, text: t.t('男') },
                { id: 2, text: t.t('女') },
                { id: 3, text: t.t('LGBTQ') },
            ];

            t.dict.gender = listToDict(t.list.gender);

        },

        setRoomName() {

            let t = this;

            if ([1, 2, 4].indexOf(t.f.lang) > -1)
                t.room_name = t.room.name;
            else
                t.room_name = t.room.name_en;

        },

        onChangeLang(lang) {
            let t = this;

            if (lang == 1) {
                window._language = 'ja-jp';
            }
            else if (lang == 2) {
                window._language = 'ja-jp';
            }
            else if (lang == 3) {
                window._language = 'en-us';
            }
            else if (lang == 4) {
                window._language = 'zh-cn';
            }
            else if (lang == 5) {
                window._language = 'ko-kr';
            }

            t.setRoomName();

            t.initGenderList();

            if (window._language != localStorage['_language']) {
                localStorage['_language'] = window._language;
                t.showForm = false;
                setTimeout(() => {
                    t.showForm = true;
                    t.$forceUpdate();
                }, 50);
            }
        },

        onSelectGender(item) {
            this.f.gender = item.id;
        },

        onSelectCountry(item) {
            this.f.country = item.code;
            this.f.tel_country = item.code;
            this.f.dcode = item.dcode;
        },

        onSelectTelCountry(item) {
            this.f.tel_country = item.code;
            this.f.dcode = item.dcode;
        },

        resetMinDate() {
            this.minDate2 = new Date();
        },

        resetMaxDate() {
            this.maxDate2 = new Date();
            this.maxDate2.setDate(this.maxDate2.getDate() + 365);
        },
        onSelectCheckInDate(d) {
            this.f.check_in_date = d.format('yyyy/MM/dd');
            d.setDate(d.getDate() + 1);
            this.minDate2 = d;
            this.showDatePopover = false;
        },

        onSelectCheckOutDate(d) {
            this.f.check_out_date = d.format('yyyy/MM/dd');
            d.setDate(d.getDate() - 1);
            this.maxDate2 = d;
            this.showDatePopover2 = false;
        },

        onGuestNumChange(v) {
            let t = this;
            if (t.lastGuestNum > 0 && v < t.lastGuestNum && (t.f.foreigner_num > 0 || t.f.rc_foreigner_num > 0)) {
                A.safety(t.t('設定の宿泊人数が前より減りましたので、再度アップロードが必要となります。続行しますか。')).then(res => {
                    if (res.isConfirmed) {
                        t.lastGuestNum = v;
                        t.f.foreigner_num = 0;
                        t.f.rc_foreigner_num = 0;
                        t.onForeignerNumChange(0);
                        t.onRCForeignerNumChange(0);
                    } else {
                        t.f.guest_num = t.lastGuestNum;
                        t.$forceUpdate();
                    }
                })
            }
            else
                t.lastGuestNum = v;

        },

        onForeignerNumChange(v) {
            let arr = this.passportUploader.items;
            this.passportUploader.items = [];
            for (let i = 0; i < v; i++) {
                if (i < arr.length && arr[i].success) {
                    this.passportUploader.items.push(arr[i]);
                }
                else
                    this.passportUploader.items.push({ success: false, data: '', img_url: '', validation: true });
            }
        },

        onRCForeignerNumChange(v) {
            let arr = this.passportUploader.items2;
            this.passportUploader.items2 = [];
            for (let i = 0; i < v; i++) {
                if (i < arr.length && arr[i].success) {
                    this.passportUploader.items2.push(arr[i]);
                }
                else
                    this.passportUploader.items2.push({ success: false, data: '', img_url: '', validation: false });
            }
        },

        closeCropper() {
            $('.cropper-item').hide();
        },

        confirmCropper() {

            let t = this;

            t.cropper.getCroppedCanvas({
                minHeight: 600,
                minWdith: 600,
                maxHeight: 1000,
                maxWdith: 1000,
                fillColor: '#fff',
            }).toBlob((blob) => {

                let fd = new FormData();

                fd.append('image', blob);

                t.passportUploader.busying = true;

                let url = t.passportUploader.current.validation ? 'form/validatePassport' : 'form/uploadRC';

                axios.post(url, fd, { headers: { 'Content-Type': 'multipart/form-data' } }).then(res => {
                    if (res.data.status == 1) {

                        t.passportUploader.busying = false;

                        if (t.passportUploader.current.validation) {

                            let passportData = res.data.options.data;

                            if (passportData) {

                                let exists = false;

                                t.passportUploader.items.forEach(item => {

                                    if (item.data && item.data.passportNumber == passportData.passportNumber) {
                                        exists = true;
                                    }

                                });

                                if (!exists) {
                                    t.passportUploader.current.data = passportData;
                                    t.passportUploader.current.success = true;
                                    t.passportUploader.current.img_url = res.data.options.img_url;
                                }
                                else {
                                    A.err(t.t('二重提出したパスポート写真がございまして、（名前：__name__）。再確認して再提出してください。').replace('__name__', passportData.nameEn ? passportData.nameEn : passportData.name));
                                }

                            }
                        }
                        else {
                            t.passportUploader.current.success = true;
                            t.passportUploader.current.img_url = res.data.options.img_url
                        }


                    }
                    else {
                        A.err(t.t('アップロードしたパスポート写真は正しく認識できませんでした。'));
                    }
                });
                t.closeCropper();
            });
        },

        uploadPicture() {

            let t = this;

            ImageUploader.onSelectImage = (file) => {

                file = event.target['files'][0];

                var blobUrl = getObjectURL(file);

                const image = document.getElementById('cropperImg');

                image.src = blobUrl;

                if (t.cropper) {
                    t.cropper.destroy();
                    t.cropper = null;
                }

                image.onload = () => {
                    t.cropper = new Cropper(image, {
                        aspectRatio: 4 / 3,
                        autoCropArea: 1,
                        crop(event) {
                        },
                    });
                    $('.cropper-item').show();
                }

                event.target['value'] = '';


            };

            ImageUploader.openSelector();
        },

        startUpload(item) {
            if (!item.success) {
                item.busying = true;
                this.passportUploader.current = item;
                this.uploadPicture();
            }
        },

        submitForm() {

            let t = this;

            let f = t.f;

            if (!f.name) {
                A.err(t.t('名前を入力してください。'));
                return;
            }

            if (!f.job) {
                A.err(t.t('職業を入力してください。'));
                return;
            }

            if (!f.gender) {
                A.err(t.t('性別を入力してください。'));
                return;
            }

            if (!f.country) {
                A.err(t.t('国・地域を選択してください。'));
                return;
            }

            if (!f.tel || f.tel < 6) {
                A.err(t.t('正確な電話番号(半角英数)を入力してください。'));
                return;
            }

            if (!f.email || /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z]{2,6})$/.test(f.email) == false) {
                A.err(t.t('正確なメールアドレス(半角英数)を入力してください。'));
                return;
            }

            if (!f.local_address) {
                A.err(t.t('ご住所を入力してください。'));
                return;
            }

            if (!f.check_in_date) {
                A.err(t.t('チェックイン日付を選択してください。'));
                return;
            }

            if (!f.check_out_date) {
                A.err(t.t('チェックアウト日付を選択してください。'));
                return;
            }

            if (!f.guest_num) {
                A.err(t.t('総人数を選択してください。'));
                return;
            }

            if (f.lang == 1) {
                // if (f.guest_num > 1 && (f.foreigner_num + f.rc_foreigner_num) < f.guest_num - 1) {
                //     A.err(t.t('外国籍の人数を選択してください。'));
                //     return;
                // }
            }
            else {
                if (f.guest_num > 0 && (f.foreigner_num + f.rc_foreigner_num) < f.guest_num) {
                    A.err(t.t('外国籍の人数を選択してください。'));
                    return;
                }
            }

            let successNum = 0;
            let passportUrls = [];
            let rcUrls = [];
            let passportData = [];

            successNum = 0;
            if (f.rc_foreigner_num > 0) {

                t.passportUploader.items2.forEach(item => {
                    if (item.success) {
                        rcUrls.push(item.img_url);
                        successNum++;
                    }
                });

                if (successNum != f.rc_foreigner_num) {
                    A.err(t.t('選択した人数分の在留カードをアップロードしてください。'));
                    return;
                }

            }

            successNum = 0;
            if (f.foreigner_num > 0) {

                t.passportUploader.items.forEach(item => {
                    if (item.success) {
                        passportUrls.push(item.img_url);
                        passportData.push(item.data);
                        successNum++;
                    }
                });

                if (successNum != f.foreigner_num) {
                    A.err(t.t('選択した人数分のパスポートをアップロードしてください。'));
                    return;
                }
            }

            // if (successNum != (f.foreigner_num + f.rc_foreigner_num)) {
            //     A.err(t.t('外国籍人数分のパスポート写真を全てアップロードしてください。'));
            //     return;
            // }

            if (!t.formIsChecked) {
                A.err(t.t('ハウスルールを確認してください。'));
                return;
            }

            if (t.busying)
                return;

            t.busying = true;

            f.passport_urls = passportUrls.join(',');
            f.passport_data = JSON.stringify(passportData);
            f.rc_urls = rcUrls.join(',');

            function _save() {

                t.busying = true;

                axios.post('form/submit', f).then(res => {

                    t.busying = false;
                    if (res.data.status == 1) {
                        
                        if(window.opener)
                            window.opener.postMessage('success', '*');
                        localStorage.removeItem('_tmpF');
                        location.href = 'https://hk.kyoran.jp/v-' + res.data.options.password_guid;
                    }
                    else if (res.data.status == 2) {
                        A.err(t.t('滞在期間が正しいかどうかをもう一度確認してください。'));
                    }
                    else if (res.data.status == 3) {
                        A.err(t.t('ご指定のご宿泊日について、すでにフォームが送信されています。'));
                    }
                });
            }

            let f2 = {
                room_id: t.room.id,
                check_in_date: t.f.check_in_date,
                check_out_date: t.f.check_out_date,
            };

            axios.post('form/checkDateRange', f2).then(res => {
                if (res.data.status == 1) {
                    _save();
                }
                else if (res.data.status == 2) {
                    t.busying = false;
                    A.err(t.t('滞在期間が正しいかどうかをもう一度確認してください。'));
                }
                else if (res.data.status == 3) {
                    t.busying = false;
                    A.err(t.t('ご指定のご宿泊日について、すでにフォームが送信されています。'));
                }
            });

        }
    },
    data() {
        return {

            showForm: true,
            room: null,

            room_name: '',
            showForm: true,
            passportUploader: {
                current: null,
                items: [
                ],
                items2: []
            },

            dict: {
                gender: [],
                country: []
            },
            list: {
                lang: [
                    { id: 1, text: '日本人' },
                    { id: 2, text: '「在留カード」を持ち外国人' },
                    { id: 3, text: 'English' },
                    { id: 4, text: '中文' },
                    { id: 5, text: '한국어' },
                ],
                guest_num: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
                foreigner_num: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
                gender: []
            },

            f: {

            },

            formIsChecked: false,

            originF: {
                lang: 1,
                name: '',
                gender: null,
                country: 'jp',
                tel_country: 'jp',
                dcode: '81',
                tel: '',
                email: '',
                check_in_date: '',
                check_out_date: '',
                guest_num: 0,
                foreigner_num: 0,
                rc_foreigner_num: 0,
                local_address: '',
                passport_urls: '',
                passport_data: '',
                message: '',
                job: ''
            },

            // originF: {
            //     lang: 2,
            //     name: 'Exia',
            //     gender: 1,
            //     country: 'cn',
            //     tel_country: 'cn',
            //     dcode: '86',
            //     tel: '15500001111',
            //     email: 'exia@qq.com',
            //     check_in_date: '2023/11/18',
            //     check_out_date: '2023/11/19',
            //     guest_num: 3,
            //     foreigner_num: 1,
            //     rc_foreigner_num: 1,
            //     local_address: 'void street zero 001 cell',
            //     passport_urls: '',
            //     passport_data: '',
            //     message: 'hello',
            // },

            lastGuestNum: 0,
            showGenderPopover: false,
            showCountryPopover: false,
            showTelCountryPopover: false,
            showDatePopover: false,
            showDatePopover2: false,

            minDate: null,
            maxDate: null,

            minDate2: null,
            maxDate2: null,



        };
    },
    watch: {
        'f': {
            handler(newValue, oldValue) {
                localStorage['_tmpF'] = JSON.stringify(newValue);
            },
            deep: true
        }
    }
};
