

export default {

    created() {

        let t = this;
        document.body.classList.remove('pc');

    },
    activated() {

        let t = this;
        
        if (localStorage['_m']) {
            location.replace('/#/cleaner');
        }

    },
    methods: {

        signIn() {
            let t = this;
            let f = t.f;

            if (t.busying)
                return;

            mirror(t.msg_o, t.msg);

            let go = true;

            if (!f.email) {
                t.msg.email = 'Required';
                go = false;
            }

            if (!f.password) {
                t.msg.password = 'Required';
                go = false;
            }

            if (!go)
                return;


            t.busying = true;

            axios.post('employee/signIn', f).then(res => {

                t.busying = false;

                if (res.data.status == 1) {
                    let user = res.data.options.item;
                    localStorage['_m'] = JSON.stringify(user);

                    t.afterLogin();

                    if(user.role == 1){
                        location.replace('/#/v2');
                    }
                    else{
                        location.replace('/#/cleaner');
                    }
                    
                }
                else {
                    A.err('Email or password incorrect, please check.');
                }

            });

        }
    },
    data() {
        return {
            msg: {
                email: '',
                password: '',
            },
            msg_o: {
                email: '',
                password: '',
            },
            f: {
                email: '',
                password: '',
            },

            list: {
            },

        };
    },
};
