

export default {
    name: "DoSupTasks",
    props: {
    },
    created() {

        let t = this;

        t.curDay = {
            date: t.today.format('yyyy-MM-dd'),
            day_suffix: ["日", "月", "火", "水", "木", "金", "土"][t.today.getDay()],
            rooms: []
        };

        axios.get('room').then(res => {
            if (res.data.status == 1) {
                let rooms = res.data.options.items;
                t.roomDict = listToDict(rooms);
                t.list.room = rooms;
                t.loadRoom();
            };
        });

        axios.get('item?_appends=cates').then(res => {

            if (res.data.status == 1) {
                let cates = res.data.options.cates;

                cates.forEach(cate => {
                    cate.items = [];
                    cate.roomItems = [];
                });

                let dictCate = listToDict(cates);

                let items = res.data.options.items;

                items.forEach(item => {
                    item.editMode = false;
                    item._quantity = item.quantity;
                    item._price = item.price;

                    if (item.cate_id && dictCate[item.cate_id])
                        dictCate[item.cate_id].items.push(item);
                });

                t.list.cate = cates;

            }
        });

    },
    deactivated() {
    },
    methods: {

        prevDay() {

            let t = this;

            let d = new Date(t.curDay.date);

            // if (d.getDay() > 2) {
            d.setDate(d.getDate() - 1);
            t.curDay = {
                date: d.format('yyyy-MM-dd'),
                day_suffix: ["日", "月", "火", "水", "木", "金", "土"][d.getDay()],
                rooms: [],
                beforeLastWeek: false,
            };

            t.curDay.beforeLastWeek = t.getMondayOfThisWeek().valueOf() > d.valueOf();

            t.loadRoom();
            // }

        },

        nextDay() {

            let t = this;

            let d = new Date(t.curDay.date);

            let days = t.calcDateDiffDays(t.today.format('yyyy-MM-dd'), t.curDay.date);

            if (days >= 31) {
                A.err('1ヶ月先までのタスクを表示');
                return;
            }

            // if (d.getDay() != 0 && d.getDay() <= 6) {
            d.setDate(d.getDate() + 1);
            t.curDay = {
                date: d.format('yyyy-MM-dd'),
                day_suffix: ["日", "月", "火", "水", "木", "金", "土"][d.getDay()],
                rooms: [],
                beforeLastWeek: false,
            };
            t.loadRoom();
            // }


        },

        loadRoom() {

            let t = this;

            t.curRoom = null;

            let eid = t.U.id;

            let p = {
                date: [t.curDay.date, t.curDay.date].join(','),
                confirmed: 1,
                employee_id: eid
            };

            t.curDay.rooms.clear();

            axios.get('supTask', { params: p }).then(res => {

                if (res.data.status == 1) {

                    let items = res.data.options.items;

                    items.forEach(item => {
                        t.roomDict[item.room_id].status = item.status;
                        t.roomDict[item.room_id].alert = item.alert;
                        t.roomDict[item.room_id].confirmed = item.confirmed;
                        t.roomDict[item.room_id].sup_task_id = item.id;
                        t.curDay.rooms.push(t.roomDict[item.room_id]);
                    });

                    t.$forceUpdate();
                }
            });

        },

        selectRoom(room) {

            let t = this;

            t.curRoom = room;

            if (room.alert == 1) {
                t.showChangedAlert = true;
                return;
            }

            t.supRoom(room);
        },

        editRoom(room, fnAfter) {

            let t = this;

            t.room = room;

            axios.get('item/readByRoom/' + room.id).then(res => {
                if (res.data.status == 1) {

                    t.room.clean_areas = res.data.options.clean_areas;

                    t.list.cate.forEach(cate => {
                        cate.roomItems = [];
                    });

                    let dictCate = listToDict(t.list.cate);

                    let items = res.data.options.items;

                    items.forEach(item => {
                        item.enabled = true;
                        item.editMode = false;
                        item._quantity = item.quantity;
                        item.supItem = {
                            quantity: 0,
                            _quantity: 0
                        };
                        if (item.cate_id && dictCate[item.cate_id])
                            dictCate[item.cate_id].roomItems.push(item);
                    });

                    t.dictRoomItem = listToDict(items, 'item_id');

                    if (fnAfter)
                        fnAfter();
                }
            });

        },

        supRoom(item) {

            let t = this;


            t.editRoom(item, () => {

                let sd = t.getMondayOfThisWeek(new Date(t.curDay.date));
                let ed = new Date(sd.format('yyyy-MM-dd'));
                ed.setDate(ed.getDate() + 6);
                
                let p = {
                    start_date: sd.format('yyyy-MM-dd'),
                    end_date: ed.format('yyyy-MM-dd')
                };

                axios.get('weeklySupItem/readByRoom/' + t.room.id, { params: p }).then(res => {
                    if (res.data.status == 1) {
                        let supTask = res.data.options.sup_task;
                        let supTakItems = res.data.options.sup_task_items;
                        let cleanDates = res.data.options.clean_dates;

                        t.list.sup_date = cleanDates;

                        if (supTakItems) {
                            supTakItems.forEach(supItem => {
                                supItem._quantity = supItem.quantity;
                                t.dictRoomItem[supItem.item_id].supItem = supItem;
                            });
                        }

                        t.list.cate.forEach(cate => {
                            cate.roomItems.forEach(roomItem => {
                                let last = supTask.cur_times % roomItem.frequency;
                                if (last != 0)
                                    roomItem.enabled = false;
                            });
                        });

                        t.supTask = supTask;
                    }
                });

            });
        },

        save(closePopup) {

            let t = this;

            if (t.supTask.id) {

                let arr = [];

                //已有补充任务
                t.list.cate.forEach(cate => {
                    cate.roomItems.forEach(roomItem => {
                        if (roomItem.supItem._quantity != roomItem.supItem.quantity) {

                            if (roomItem.supItem.id) {
                                arr.push({
                                    'id': roomItem.supItem.id,
                                    'quantity': roomItem.supItem._quantity,
                                });
                            }
                            else {
                                arr.push({
                                    'item_id': roomItem.item_id,
                                    'quantity': roomItem.supItem._quantity,
                                    'sup_task_id': t.supTask.id
                                });
                            }
                            roomItem.supItem.quantity = roomItem.supItem._quantity;
                        }
                    });
                });

                if (arr.length > 0) {
                    axios.post('weeklySupItem/batchSaveSupTaskItems', { 'json_items': JSON.stringify(arr) }).then(res => {
                        if (res.data.status == 1) {
                            A.toast('Saved.');
                        }
                    });
                }

                axios.put('supTask/' + t.supTask.id, { status: closePopup ? 3 : 2 }).then(res => {
                    if (res.data.status == 1) {
                        if (arr.length == 0)
                            A.toast('Saved.');

                        t.curRoom.status = closePopup ? 3 : 2;
                        t.$forceUpdate();
                    }
                });

            }
            else {
                A.err("今週の補足タスクはまだ設定されていませんので、補足アクションを実行する前に設定してください。");
            }

        },

        removeSupTask() {

            let t = this;

            let room = t.curRoom;

            axios.delete('supTask/' + room.sup_task_id).then(res => {
                if (res.data.status == 1) {

                    t.sendMessage({
                        type: 'unlock',
                        date: t.curDay.date,
                        room_ids: [room.id],
                    });

                    t.curDay.rooms.remove(room);

                    t.curRoom = null;
                }
            });

        },

    },
    data() {
        return {
            showChangedAlert: false,
            supTask: null,
            today: Date.today(),
            curDay: Date.today().format('yyyy-MM-dd'),
            curRoom: null,
            dictRoomItem: {},
            roomDict: {},
            list: {
                cate: [],
                room: [],
                sup_date: [],
            },
        };
    },
};

