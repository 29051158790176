

export default {
    name: 'all_stocks',
    created() {

        let t = this;

        document.body.classList.add('pc');

        t.loadData();

    },
    activated() {

        let t = this;

    },
    methods: {

        loadData() {

            let t = this;

            axios.get('item?_appends=cates').then(res => {

                if (res.data.status == 1) {
                    let cates = res.data.options.cates;

                    cates.forEach(cate => {
                        cate.items = [];
                        cate.roomItems = [];
                    });

                    let dictCate = listToDict(cates);

                    let items = res.data.options.items;

                    items.forEach(item => {
                        item.editMode = false;
                        item._quantity = item.quantity;
                        item._price = item.price;

                        if (item.cate_id && dictCate[item.cate_id])
                            dictCate[item.cate_id].items.push(item);
                    });

                    t.list.cate = cates;

                }
            });

            axios.get('room').then(res => {

                if (res.data.status == 1) {
                    let rooms = res.data.options.items;

                    rooms.forEach(room => {
                        room.clean_areas = [];
                    });

                    t.list.room = rooms;

                }
            });

        },

        editRoom(room, fnAfter) {

            let t = this;

            t.room = room;

            axios.get('item/readByRoom/' + room.id).then(res => {
                if (res.data.status == 1) {

                    t.room.clean_areas = res.data.options.clean_areas;

                    t.list.cate.forEach(cate => {
                        cate.roomItems = [];
                    });

                    let dictCate = listToDict(t.list.cate);

                    let items = res.data.options.items;

                    items.forEach(item => {
                        item.enabled = true;
                        item.editMode = false;
                        item._quantity = item.quantity;
                        item.supItem = {
                            quantity: 0,
                            _quantity: 0
                        };
                        if (item.cate_id && dictCate[item.cate_id])
                            dictCate[item.cate_id].roomItems.push(item);
                    });

                    t.dictRoomItem = listToDict(items, 'item_id');

                    if (fnAfter)
                        fnAfter();
                    else {
                        t.showRoomEditor = true;
                    }
                }
            });

        },

        cancelEdit(item) {
            item.editMode = false;
            item._price = item.price;
            item._quantity = item.quantity;
            event.stopPropagation();
        },


        applyEdit(item) {

            item.editMode = false;
            item.price = item._price;
            item.quantity = item._quantity;

            axios.put('item/' + item.id, { id: item.id, price: item.price, quantity: item.quantity }).then(res => {
                if (res.data.status == 1) {

                }
            });

            event.stopPropagation();
        },

        cancelEdit2(item) {
            item.editMode = false;
            item._quantity = item.quantity;
            item._frequency = item.frequency;
            event.stopPropagation();
        },


        applyEdit2(item) {

            item.editMode = false;
            item.quantity = item._quantity;
            item.frequency = item._frequency;

            axios.put('weeklySupItem/' + item.id, { id: item.id, quantity: item.quantity, frequency: item.frequency }).then(res => {
                if (res.data.status == 1) {

                }
            });

            event.stopPropagation();
        },

        supRoom(item) {

            let t = this;

            t.editRoom(item, () => {
                if (!t.curWeek.start_date) {
                    t.curWeek.start_date = t.getMondayOfThisWeek().format('yyyy-MM-dd');
                    let endDate = new Date(t.curWeek.start_date);
                    endDate.setDate(endDate.getDate() + 6);
                    t.curWeek.end_date = endDate.format('yyyy-MM-dd');
                    t.curWeek.text = new Date(t.curWeek.start_date).format('MM/dd') + ' ~ ' + new Date(t.curWeek.end_date).format('dd');
                }

                let p = {
                    start_date: t.curWeek.start_date,
                    end_date: t.curWeek.end_date,
                };

                axios.get('weeklySupItem/readByRoom/' + t.room.id, { params: p }).then(res => {
                    if (res.data.status == 1) {
                        let supTask = res.data.options.sup_task;
                        let supTakItems = res.data.options.sup_task_items;
                        let cleanDates = res.data.options.clean_dates;

                        t.list.sup_date = cleanDates;

                        if (supTakItems) {
                            supTakItems.forEach(supItem => {
                                supItem._quantity = supItem.quantity;
                                t.dictRoomItem[supItem.item_id].supItem = supItem;
                            });
                        }

                        t.list.cate.forEach(cate => {
                            cate.roomItems.forEach(roomItem => {
                                let last = supTask.cur_times % roomItem.frequency;
                                if (last != 0)
                                    roomItem.enabled = false;
                            });
                        });

                        t.supTask = supTask;
                        t.showWeeklySupEditor = true;
                    }
                });

            });
        },

        prevWeek() {

            let t = this;

            let startDate = new Date(t.curWeek.start_date);
            startDate.setDate(startDate.getDate() - 7);
            t.curWeek.start_date = startDate.format('yyyy-MM-dd');

            let endDate = new Date(t.curWeek.start_date);
            endDate.setDate(endDate.getDate() + 6);
            t.curWeek.end_date = endDate.format('yyyy-MM-dd');
            t.curWeek.text = new Date(t.curWeek.start_date).format('MM/dd') + ' ~ ' + new Date(t.curWeek.end_date).format('dd');

            t.supRoom(t.room);
        },

        nextWeek() {

            let t = this;

            let now = (new Date((new Date()).format('yyyy-MM-dd'))).valueOf();
            if ((new Date(t.curWeek.start_date)).valueOf() <= now &&
                (new Date(t.curWeek.end_date)).valueOf() >= now
            ) {
                A.err('現在の週までのデータのみ閲覧・修正が可能。');
                return;
            }

            let startDate = new Date(t.curWeek.start_date);
            startDate.setDate(startDate.getDate() + 7);
            t.curWeek.start_date = startDate.format('yyyy-MM-dd');

            let endDate = new Date(t.curWeek.start_date);
            endDate.setDate(endDate.getDate() + 6);
            t.curWeek.end_date = endDate.format('yyyy-MM-dd');
            t.curWeek.text = new Date(t.curWeek.start_date).format('MM/dd') + ' ~ ' + new Date(t.curWeek.end_date).format('dd');

            t.supRoom(t.room);
        },

        resetCurWeek() {
            this.curWeek = {
                text: '',
                start_date: '',
                end_date: ''
            };
        },

        save(closePopup) {

            let t = this;

            if (t.supTask.id) {

                let arr = [];

                //已有补充任务
                t.list.cate.forEach(cate => {
                    cate.roomItems.forEach(roomItem => {
                        if (roomItem.supItem._quantity != roomItem.supItem.quantity) {

                            if (roomItem.supItem.id) {
                                arr.push({
                                    'id': roomItem.supItem.id,
                                    'quantity': roomItem.supItem._quantity,
                                });
                            }
                            else {
                                arr.push({
                                    'item_id': roomItem.item_id,
                                    'quantity': roomItem.supItem._quantity,
                                    'sup_task_id': t.supTask.id
                                });
                            }
                            roomItem.supItem.quantity = roomItem.supItem._quantity;
                        }
                    });
                });

                if (arr.length > 0) {
                    axios.post('weeklySupItem/batchSaveSupTaskItems', { 'json_items': JSON.stringify(arr) }).then(res => {
                        if (res.data.status == 1) {
                            A.toast('Saved.');
                        }
                    });
                }

                axios.put('supTask/' + t.supTask.id, { status: closePopup ? 3 : 2 }).then(res => {
                    if (res.data.status == 1) {

                    }
                });


                if (closePopup) {
                    t.showWeeklySupEditor = false;
                }

            }
            else {
                A.err("今週の補足タスクはまだ設定されていませんので、補足アクションを実行する前に設定してください。");
            }

        },

        editSupTask() {
            let t = this;
            if (!t.supTask.id) {
                A.err("今週の補足タスクはまだ設定されていませんので、補足アクションを実行する前に設定してください。");
                return;
            }
            t.modifyMode = true;
        }

    },
    data() {
        return {
            supTask: {},
            modifyMode: false,
            curWeek: {
                text: '09/02 ~ 08',
                start_date: '2024-09-02',
                end_date: '2024-09-08'
            },
            showWeeklySupEditor: false,
            showRoomEditor: false,
            room: {},
            dictRoomItem: {},
            list: {
                cate: [],
                cate2: [],
                room: [],
                roomItem: [],
                sup_date: []
            },
        };
    },
    computed: {
    }
};
